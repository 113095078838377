import React, { useEffect, useState } from "react";
import "./arrow.css";

const Arrow = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 4500);
  }, [4500]);

  return visible ? (
    <div className="arrow">
      <span></span>
      <span></span>
      <span></span>
    </div>
  ) : (
    <div />
  );
};

export default Arrow;
